import {screenMedium, screenLarge, screenXlarge} from '../utils'
import {debounce} from 'ts-debounce'

export default class Parallax {
    private parallaxItems: NodeListOf<Element>

    constructor() {
        this.parallaxItems = document.querySelectorAll('[data-parallax]')
        if (!this.parallaxItems) return
        this.setEventListeners()
        this.parallax()
    }

    // the parallax function
    private parallax() {
        let transformY = '25px'
        if (screenXlarge.matches) {
            transformY = '100px'
        } else if (screenLarge.matches) {
            transformY = '75px'
        } else if (screenMedium.matches) {
            transformY = '75px'
        }

        for (const item of this.parallaxItems) {
            const y = window.innerHeight - item.getBoundingClientRect().top
            let scale = item.getAttribute('data-parallax-scale')

            if (!scale || isNaN(scale)) {
                scale = 0
            }

            if (y > 0) {
                item.style.transform =
                    'translate3d(0, -' +
                    this.calculateScale(scale) * y +
                    'px ,0) translateY(' +
                    transformY +
                    ')'
            }
        }
    }

    // function to calculate the multiplier
    private calculateScale(scale) {
        const originalScale = scale

        if (screenXlarge.matches) {
            scale = scale * 2
        } else if (screenLarge.matches) {
            scale = scale * 1.75
        } else if (screenMedium.matches) {
            scale = scale * 1.5
        }

        if (!scale || isNaN(scale)) {
            return originalScale
        } else {
            return scale
        }
    }

    // event listeners
    private setEventListeners() {
        // on window resize, reset parallax items
        window.addEventListener('resize', () => {
            debounce(this.parallax, 150)
        })

        // call the function on scroll
        window.onscroll = () => {
            this.parallax()
        }
    }
}
