import '../scss/main.scss'
import Preloader from './components/preloader'
import MainNav from './components/main-nav'
import ContentSliders from './components/content-slider'
import ImageSlider from './components/image-slider'
import Video from './components/video'
import Parallax from './components/parallax'
import Faq from './components/faq'
import HorTextScroller from './components/horizontal-text-scroller'
import AnimateOnScroll from './components/animate-on-scroll'
import TableOfContents from './components/table-of-contents'
import Alert from './components/alert'
import EventsFilters from './components/events-filters'
import StudiesOverview from './components/studies-overview'

class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            new Preloader()
            new MainNav()
        })

        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'complete') {
                new Alert()
                new ContentSliders()
                new ImageSlider()
                new Video()
                new Parallax()
                new Faq()
                new HorTextScroller()
                new AnimateOnScroll()
                new TableOfContents()
                new EventsFilters()
                new StudiesOverview()
            }
        })
    }
}
window['App'] = new App()
