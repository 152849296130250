import {addClass, removeClass} from '../utils'
import {debounce} from 'ts-debounce'

export default class ContentSliders {
    constructor() {
        const contentSliders = document.querySelectorAll('.ce-content-slider')
        for (const contentSlider of contentSliders) {
            new ContentSlider(contentSlider)
        }
    }
}

class ContentSlider {
    private nav: HTMLElement
    private navButtons: NodeListOf<Element>
    private navButtonActiveAtt: string
    private slidesContainer: HTMLElement
    private slides: NodeListOf<Element>
    private slideActiveClass: string
    private slidesHeight: number
    private navItems: NodeListOf<Element>

    constructor(sliderContainer: Element) {
        this.nav = sliderContainer.querySelector('.ce-content-slider__nav')
        this.navItems = this.nav.querySelectorAll('ul > li')
        this.navButtons = sliderContainer.querySelectorAll(
            '.ce-content-slider__nav button'
        )
        this.navButtonActiveAtt = 'data-slide-control-active'
        this.slidesContainer = sliderContainer.querySelector(
            '.ce-content-slider__slides'
        )
        this.slides = sliderContainer.querySelectorAll(
            '.ce-content-slider__slide'
        )
        this.slideActiveClass = 'ce-content-slider__slide--active'
        this.slidesHeight = 0
        this.init()
    }

    // init
    private init() {
        this.hideAllSlides()
        this.setSlidesHeight()
        this.showSlide(this.slides[0], this.navButtons[0])
        this.setEventListeners()
    }

    // event listeners
    private setEventListeners() {
        // nav buttons
        for (const button of this.navButtons) {
            button.addEventListener('click', () => {
                let target = parseInt(button.getAttribute('data-slide-control'))
                if (isNaN(target)) {
                    target = 0
                    console.warn(`Target of ${button} is NaN`)
                } else {
                    target--
                }

                if (button.getAttribute(this.navButtonActiveAtt) == 'true')
                    return

                this.hideAllSlides()
                this.showSlide(this.slides[target], button)
            })
        }

        // handle window resize
        const resize = () => {
            this.slidesHeight = 0
            this.setSlidesHeight()
        }
        const debouncedFunction = debounce(resize, 150)
        window.addEventListener('resize', () => {
            debouncedFunction()
        })

        // on nav scroll
        const handleNavScroll = () => {
            const navInd = this.nav.querySelector(
                '.ce-content-slider__indicator'
            )
            navInd.classList.add('opacity-0') // hide the indicator
            this.removeOpacity() // remove opacity from list items
            this.nav.removeEventListener('touchmove', handleNavScroll) // remove the event handler
            setTimeout(() => {
                navInd.remove()
            }, 300) // remove the element
        }
        this.nav.addEventListener('touchmove', handleNavScroll)
    }

    // set height of slides container
    private setSlidesHeight() {
        // get the height of the highest slide
        const highestSlide = Array.from(this.slides).sort(
            (a, b) => a.scrollHeight - b.scrollHeight
        )[this.slides.length - 1].scrollHeight

        // set height on the container
        this.slidesContainer.style.height = `${highestSlide}px`
    }

    // hide all slides
    private hideAllSlides() {
        // hide slides
        for (const slide of this.slides) {
            removeClass(slide, this.slideActiveClass)
        }
        // deactive buttons
        for (const button of this.navButtons) {
            button.setAttribute(this.navButtonActiveAtt, 'false')
        }
    }

    // show slide
    private showSlide(slide, button) {
        addClass(slide, this.slideActiveClass)
        button.setAttribute(this.navButtonActiveAtt, 'true')
    }

    private removeOpacity() {
        for (const navItem of this.navItems) {
            removeClass(navItem, 'sm-max:opacity-0')
        }
    }
}
