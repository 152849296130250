/* eslint-disable @typescript-eslint/ban-ts-comment */
export const addClass = (el: HTMLElement, className: string) => {
    el.classList.add(className)
}

export const removeClass = (el: HTMLElement, className: string) => {
    el.classList.remove(className)
}

export const hasClass = (el: HTMLElement, className: string) => {
    return el.classList.contains(className)
}

export const toggleClass = (el: HTMLElement, className: string) => {
    return el.classList.toggle(className)
}

export const trapFocus = (element: HTMLElement) => {
    const focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    )
    const firstFocusableEl = focusableEls[0]
    const lastFocusableEl = focusableEls[focusableEls.length - 1]
    const KEYCODE_TAB = 9

    element.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

        if (!isTabPressed) {
            return
        }

        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                // @ts-ignore
                lastFocusableEl.focus()
                e.preventDefault()
            }
        } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
                // @ts-ignore
                firstFocusableEl.focus()
                e.preventDefault()
            }
        }
    })
}

// media queries
export const screenMedium = window.matchMedia('(min-width: 768px)')
export const screenLarge = window.matchMedia('(min-width: 1024px)')
export const screenXlarge = window.matchMedia('(min-width: 1160px)')
export const reducedMotion = window.matchMedia('(prefers-reduced-motion)')
