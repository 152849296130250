export default class AnimateOnScroll {
    constructor() {
        const animations = document.querySelectorAll('[data-aos]')
        if (!animations) return
        new Animation(animations)
    }
}

class Animation {
    private aosLeftClass: string
    private aosBottomClass: string

    constructor(animations: NodeList) {
        this.aosLeftClass = 'fade-in-left'
        this.aosBottomClass = 'fade-in-bottom'
        this.intersectionObserver(animations)
    }

    private intersectionObserver(els) {
        const animate = (target) => {
            const io = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const target = entry.target
                            if (target.hasAttribute('data-aos-left')) {
                                target.classList.add(this.aosLeftClass)
                            } else if (target.hasAttribute('data-aos-bottom')) {
                                target.classList.add(this.aosBottomClass)
                            }
                            observer.disconnect()
                        }
                    })
                },
                {threshold: [0.3]}
            )
            io.observe(target)
        }
        els.forEach(animate)
    }
}
