import {addClass, removeClass, hasClass} from '../utils'

export default class MainNav {
    private mainNavToggle: HTMLElement
    private mainNav: HTMLElement
    private mainNavVisibleClass: string
    private navLinks: NodeListOf<Element>
    private subMenus: NodeListOf<Element>
    private navSubLinks: NodeListOf<Element>
    private navSubmenuToggles: NodeListOf<Element>
    private searchFormInput: HTMLElement
    private searchFormSubmit: HTMLElement
    private focusableElements: Array<Element>

    constructor() {
        this.mainNavToggle = document.getElementById('hamburger-button')
        this.mainNav = document.getElementById('main-nav')
        this.mainNavVisibleClass = 'main-nav-wraper--visible'
        this.navLinks = this.mainNav.querySelectorAll('a')
        this.subMenus = this.mainNav.querySelectorAll('.main-nav__submenu')
        this.navSubLinks = this.mainNav.querySelectorAll('.main-nav__submenu a')
        this.navSubmenuToggles = this.mainNav.querySelectorAll(
            '.main-nav__submenu-toggle'
        )
        this.searchFormInput = this.mainNav.querySelector(
            '.search-form input[type="search"]'
        )
        this.searchFormSubmit = this.mainNav.querySelector(
            '.search-form button[type="submit"]'
        )
        this.focusableElements = []
        this.init()
        this.setEventListeners()
    }

    // init
    private init() {
        // adds transform transition class for slide in animation
        setTimeout(() => {
            addClass(this.mainNav, 'transition-transform')
        }, 150)

        // push nav links to focuable array
        for (const el of this.navLinks) {
            this.focusableElements.push(el)
        }

        // push nav submenu toggle buttons to focuable array
        for (const el of this.navSubmenuToggles) {
            this.focusableElements.push(el)
        }

        // push search field elements to focuable array
        this.focusableElements.push(this.searchFormInput, this.searchFormSubmit)

        // set negative tabindex to focusable elements
        this.setTabIndex(true, this.focusableElements)

        // close all submenus
        for (const submenu of this.subMenus) {
            this.toggleSubMenu(submenu, 'close')
        }
    }

    // function to toggle tabindex
    private setTabIndex(bool, arr) {
        if (bool) {
            for (const el of arr) {
                el.tabIndex = -1
            }
        } else {
            for (const el of arr) {
                el.tabIndex = ''
            }
        }
    }

    // event handlers
    private setEventListeners() {
        // main nav toggle button
        this.mainNavToggle.onclick = () => {
            // open menu
            if (!hasClass(this.mainNav, this.mainNavVisibleClass)) {
                this.toggleNav('open')
            }

            // close menu
            else {
                this.toggleNav('close')
            }
        }

        // on escape press
        document.onkeydown = (e) => {
            if (
                e.key === 'Escape' &&
                hasClass(this.mainNav, this.mainNavVisibleClass)
            ) {
                this.toggleNav('close')
                this.mainNavToggle.focus()
            }
        }

        // submenu toggle
        for (const button of this.navSubmenuToggles) {
            const openLabel = button.querySelector(
                '.main-nav__submenu-toggle-label--open'
            )
            const closeLabel = button.querySelector(
                '.main-nav__submenu-toggle-label--close'
            )
            const submenu =
                button.parentNode.querySelector('.main-nav__submenu')

            button.onclick = () => {
                // open submenu
                if (button.getAttribute('aria-expanded') == 'false') {
                    this.toggleSubMenu(submenu, 'open')
                    button.setAttribute('aria-expanded', 'true')
                    addClass(openLabel, 'hidden')
                    removeClass(closeLabel, 'hidden')
                }

                // close submenu
                else {
                    this.toggleSubMenu(submenu, 'close')
                    button.setAttribute('aria-expanded', 'false')
                    removeClass(openLabel, 'hidden')
                    addClass(closeLabel, 'hidden')
                }
            }
        }
    }

    // toggle nav
    private toggleNav(state) {
        if (state == 'open') {
            addClass(this.mainNav, this.mainNavVisibleClass)
            addClass(document.body, 'overflow-hidden')
            addClass(document.body, 'menu-open')

            // toggle aria-expanded
            this.mainNavToggle.setAttribute('aria-expanded', 'true')

            // scroll top top
            location.href = '#'

            // remove tabindexes
            this.setTabIndex(false, this.focusableElements)

            // set tabindex on toggle buttons
            this.setTabIndex(true, this.navSubLinks)
        } else {
            removeClass(this.mainNav, this.mainNavVisibleClass)
            removeClass(document.body, 'overflow-hidden')
            removeClass(document.body, 'menu-open')

            // toggle aria-expanded
            this.mainNavToggle.setAttribute('aria-expanded', 'false')

            this.setTabIndex(true, this.focusableElements)
        }
    }

    // toggle submenus
    private toggleSubMenu(el, state) {
        const subLinks = el.querySelectorAll('a')
        if (state == 'open') {
            el.style.maxHeight = el.scrollHeight + 'px'
            this.setTabIndex(false, subLinks)
        } else {
            el.style.maxHeight = '0px'
            this.setTabIndex(true, subLinks)
        }
    }
}
