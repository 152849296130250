import {addClass} from '../utils'
export default class Preloader {
    private body: HTMLElement
    private preloadAlert: HTMLElement
    private preloader: HTMLElement
    private preloaderAnimCls: string
    private logo: HTMLElement
    private animationFinished: boolean
    private readyState: boolean

    constructor() {
        this.body = document.querySelector('body')
        this.preloadAlert = document.querySelector('.preload-alert')
        this.preloader = document.querySelector('.preloader')
        this.preloaderAnimCls = 'preloader--animate'
        this.animationFinished = false
        this.readyState = false
        this.logo = document.querySelector('.preloader svg.logo')
        if (this.preloader) this.init()
    }

    private init() {
        // check if loading is complete and set readyState
        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'complete') {
                this.readyState = true
                removePreloader()
            }
        })

        // if animation of preloader has ended
        this.logo.addEventListener(
            'animationend',
            (e) => {
                if (e.target === this.logo) {
                    this.animationFinished = true
                    removePreloader()
                }
            },
            false
        )

        // remove the preloader when animation is finished and document is ready
        const removePreloader = () => {
            if (this.readyState && this.animationFinished) {
                addClass(this.preloader, this.preloaderAnimCls) // add exit animation class

                // when animation is finished
                this.preloader.addEventListener('animationend', (e) => {
                    if (e.target === this.preloader) {
                        this.body.removeAttribute('data-preloader') // remove the data-preloader attribute from body
                        this.preloadAlert.innerText =
                            this.preloadAlert.getAttribute(
                                'data-preload-alert-done'
                            ) // set the preload alert to done
                        this.preloader.remove() // remove the preloader from the DOM
                    }
                })
            }
        }
    }
}
