export default class StudiesOverview {
    private filtersForm: HTMLFormElement

    constructor() {
        this.filtersForm = document.querySelector<HTMLFormElement>('.nimeto-filtered-studies-list')

        if (this.filtersForm) this.init()
        
    }

    private init() {
        const filtersDropdowns = this.filtersForm.querySelectorAll<HTMLElement>('.studies__filters')
        
        filtersDropdowns.forEach(dropdown => {
            const summary = dropdown.querySelector<HTMLElement>('.studies__filters-summary')

            summary?.addEventListener('click', () => {
                const dropdownIsOpen = dropdown.classList.contains('studies__filters--open')

                ;[...filtersDropdowns]
                    .filter(dropdown => dropdown.classList.contains('studies__filters--open'))
                    .forEach(dropdown => {
                        this.toggleDropdown(dropdown)
                    })
                
                if (!dropdownIsOpen) {
                    this.toggleDropdown(dropdown)
                }
            })
        })
    }

    toggleDropdown(dropdown: HTMLElement) {
        const summary = dropdown.querySelector<HTMLElement>('.studies__filters-summary')
        const details = dropdown.querySelector<HTMLElement>('.studies__filters-details')
        
        dropdown?.classList.toggle('studies__filters--open')
        details?.classList.toggle('hidden')
        details?.classList.toggle('flex')

        summary?.setAttribute('aria-expanded', summary?.getAttribute('aria-expanded') === 'false' ? 'true': 'false')
    }
}
