import Swiper, {Navigation} from 'swiper'
import 'swiper/css'
Swiper.use([Navigation])

export default class ImageSlider {
    private imageSliders: NodeListOf<HTMLElement>

    constructor() {
        this.imageSliders = document.querySelectorAll('.ce-image-slider')
        this.init()
    }

    private init() {
        if (!this.imageSliders) return

        for (const slider of this.imageSliders) {
            // Init slider
            new Swiper(slider.querySelector('.swiper') as Element, {
                slidesPerView: 2,
                freeMode: true,
                spaceBetween: 32,
                centeredSlides: true,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })

            // Configure lightbox
            const slides = slider.querySelectorAll(
                '.swiper-slide'
            ) as NodeListOf<HTMLElement>
            const lightbox = slider.querySelector(
                '.image-slider__lightbox'
            ) as HTMLElement
            const lightboxImage = lightbox.querySelector(
                '.image-slider__lightbox-image'
            ) as HTMLImageElement
            const lightboxClose = lightbox.querySelector(
                '.image-slider__lightbox-close'
            ) as HTMLButtonElement

            slides.forEach((slide) => {
                slide.addEventListener('click', () => {
                    const imageUrl = slide.dataset.imageUrl

                    if (imageUrl) {
                        lightboxImage.src = imageUrl
                        lightboxClose.disabled = false
                        lightbox.classList.remove(
                            'pointer-events-none',
                            'opacity-0'
                        )
                    }
                })
            })

            const closeLightbox = () => {
                lightbox.classList.add('pointer-events-none', 'opacity-0')

                setTimeout(() => {
                    lightboxImage.src = ''
                    lightboxClose.disabled = true
                }, 150)
            }

            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape') {
                    closeLightbox()
                }
            })
            lightbox.addEventListener('click', (event) => {
                if (event.target !== lightboxImage) {
                    closeLightbox()
                }
            })
        }
    }
}
