import {setCookie} from '../utils/cookies'

export default class Alert {
    private alert: HTMLElement
    private alertCloseBtn: HTMLElement

    constructor() {
        this.body = document.querySelector('body')
        this.alertCloseBtn = document.getElementById('alert-close-button')
        this.alert = document.getElementById('site-alert')
        if (this.alert) this.init()
    }

    // init
    private init() {
        // set the event listeners
        this.setEventListeners()

        // set body padding
        this.setBodyPadding()
    }

    // close alert
    private closeAlert() {
        this.alert.remove()
        this.setBodyPadding()
        setCookie(
            `alert-${this.alert.getAttribute('data-alert-id')}`,
            'false',
            7
        )
    }

    // event listeners
    private setEventListeners() {
        // event listener on close button
        this.alertCloseBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.closeAlert()
        })

        // event listener on resize
        window.addEventListener('resize', () => {
            this.setBodyPadding()
        })
    }

    // set the body padding
    private setBodyPadding() {
        document.documentElement.style.setProperty(
            '--alert-height',
            `${this.alert.offsetHeight ? this.alert.offsetHeight : 0}px`
        )
    }
}
