import {translate} from '../utils/translate'
export default class TableOfContents {
    constructor() {
        const table = document.querySelector('.ce-table-of-contents')
        if (!table) return
        new Table(table)
    }
}

class Table {
    private anchors: NodeListOf<Element>
    private list: HTMLElement
    private anchorLabel: string

    constructor(table: Element) {
        this.anchorLabel = 'data-anchor-label'
        this.anchors = document.querySelectorAll(`[${this.anchorLabel}]`)
        this.list = table.querySelector('.ce-table-of-contents__list')
        for (const anchor of this.anchors) {
            this.generateLinks(anchor)
        }
    }

    private generateLinks(el) {
        // create icon
        const icon = document.createElement('span')
        icon.classList.add('icon', 'icon-arrow-down', 'text-lg')
        icon.setAttribute('aria-hidden', 'true')

        // create link
        const a = document.createElement('a')
        const ariaLabel = `${translate('jumpToLabel')} ${el.getAttribute(
            this.anchorLabel
        )}`
        a.textContent = el.getAttribute(this.anchorLabel)
        a.href = `#${el.id}`
        a.classList.add(
            'flex',
            'justify-center',
            'items-center',
            'gap-2',
            'rounded-full',
            'px-6',
            'py-1'
        )
        a.setAttribute('aria-label', ariaLabel)

        a.append(icon)

        // create list item
        const li = document.createElement('li')
        li.append(a)
        this.list.append(li)
    }
}
