/* eslint-disable @typescript-eslint/ban-ts-comment */
export const translate = (item) => {
    if (!item) return ''

    const lang = document.documentElement.lang || 'en-US'

    const translations = {
        'en-US': {
            closeFsVideo: 'close full screen mode',
            jumpToLabel: 'jump to',
            muteVideo: 'mute video',
            pauseVideo: 'pause video',
            playVideo: 'play video',
            showFsVideo: 'enable full screen mode',
            unmuteVideo: 'unmute video',
        },
        nl: {
            closeFsVideo: 'sluit volledig scherm modus',
            jumpToLabel: 'spring naar',
            muteVideo: 'zet het geluid van de video uit',
            pauseVideo: 'pauzeer video',
            playVideo: 'speel video af',
            showFsVideo: 'toon video op volledig scherm',
            unmuteVideo: 'zet het geluid van de video aan',
        },
    }

    return translations[lang][item] || ''
}
