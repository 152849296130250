export default class HorTextScroller {
    constructor() {
        const scrollers = document.querySelectorAll('.ce-marquee')
        for (const scroller of scrollers) {
            new Scroller(scroller)
        }
    }
}

class Scroller {
    private content: HTMLElement
    private contentWidth: number
    private contentWrapper: HTMLElement
    private contentText: string
    private contentTextClone: string

    constructor(scroller: Element) {
        this.content = scroller.querySelector(
            '.ce-marquee__content > [data-scroller-text]'
        )
        this.contentWidth = this.content.offsetWidth
        this.contentText = this.content.getAttribute('data-scroller-text')
        this.contentTextClone = ` • ${this.contentText}`
        this.contentWrapper = scroller.querySelector('.ce-marquee__content')
        this.init()
    }

    // init
    private init() {
        this.cloneContent()
        this.addEventListeners()

        const speed = 0.2
        let i = 0

        // run with interval
        setInterval(() => {
            this.content.style.transform = `translateX(-${i}px)`
            if (i > this.contentWrapper.clientWidth) {
                i = 0
            }
            i = i + speed
        }, 0)
    }

    // clone content
    private cloneContent() {
        this.content.innerText = this.contentText
        const multiplier = Math.ceil(window.innerWidth / this.contentWidth) * 2
        for (let index = 0; index < multiplier; index++) {
            this.content.insertAdjacentText('beforeend', this.contentTextClone)
        }
    }

    // event listeners
    private addEventListeners() {
        window.addEventListener('resize', () => {
            this.cloneContent()
        })
    }
}
