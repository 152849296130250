import {addClass, removeClass, hasClass} from '../utils'

export default class Faqs {
    constructor() {
        const faqs = document.querySelectorAll('.ce-faqs')
        for (const faq of faqs) {
            new Faq(faq)
        }
    }
}

class Faq {
    private faqs: NodeListOf<Element>
    private faqClosedClass: string

    constructor(faqList: Element) {
        this.faqs = faqList.querySelectorAll('.ce-faq')
        this.faqClosedClass = 'ce-faq--closed'
        this.init()
    }

    // toggle answer on click
    // reset max-height on window resize

    // init
    private init() {
        for (const faq of this.faqs) {
            // hide all answers by default
            this.toggleAnswer(faq, 'close')

            // add event listeners
            const button = faq.querySelector('button')
            button.addEventListener('click', () => {
                if (hasClass(faq, this.faqClosedClass)) {
                    this.toggleAnswer(faq, 'open')
                    button.setAttribute('aria-expanded', 'true')
                } else {
                    this.toggleAnswer(faq, 'close')
                    button.setAttribute('aria-expanded', 'false')
                }
            })
        }
    }

    // toggle answer
    private toggleAnswer(el, state) {
        const answer = el.querySelector('.ce-faq__answer')

        if (state == 'open') {
            answer.style.maxHeight = `${answer.scrollHeight}px`
            removeClass(el, this.faqClosedClass)
        } else {
            answer.style.maxHeight = '0px'
            addClass(el, this.faqClosedClass)
        }
    }
}
