import {reducedMotion} from '../utils/index'
import {translate} from '../utils/translate'

export default class Videos {
    constructor() {
        const videoElements = document.querySelectorAll('.ce-video')
        for (const video of videoElements) {
            new VideoController(video)
        }
    }
}

class VideoController {
    private video: HTMLVideoElement
    private playButtons: NodeListOf<Element>
    private mainPlayButton: HTMLElement
    private muteButton: HTMLElement
    private videoControls: HTMLElement
    private fsButton: HTMLElement
    private fsButtonIcon: HTMLElement
    private hasInteractedWithVideo: boolean

    constructor(videoContainer: Element) {
        this.video = videoContainer.querySelector('.ce-video__video')
        this.playButtons = videoContainer.querySelectorAll(
            ".ce-video [data-video-control='play']"
        )
        this.mainPlayButton = videoContainer.querySelector(
            '.ce-video .ce-video__main-play'
        )
        this.muteButton = videoContainer.querySelector(
            ".ce-video [data-video-control='mute']"
        )
        this.fsButton = videoContainer.querySelector(
            ".ce-video [data-video-control='fs']"
        )
        this.fsButtonIcon = videoContainer.querySelector(
            ".ce-video [data-video-control='fs'] .icon"
        )
        this.videoControls = videoContainer.querySelector(
            '.ce-video .ce-video__controls'
        )

        // if the video should autoplay and when user does not prefers reduced motion
        if (
            videoContainer.classList.contains('ce-video--autoplay') &&
            !reducedMotion.matches
        ) {
            this.intersectionObserver(videoContainer) // observe the element
        }

        // check wether the full screen API is available and enabled
        if (!document?.fullscreenEnabled) {
            this.fsButton.style.display = 'none' // hide te button it it's not
        } else {
            this.fsButton.addEventListener('click', () => {
                handleFullscreen()
            })
        }

        // handle full screen
        function handleFullscreen() {
            if (document.fullscreenElement !== null) {
                // The document is in fullscreen mode
                document.exitFullscreen()
                setFullscreenData(false)
            } else {
                // The document is not in fullscreen mode
                videoContainer.requestFullscreen()
                setFullscreenData(true)
            }
        }

        // toggle fs attribute, icon and aria-label
        const setFullscreenData = (state: boolean) => {
            if (state) {
                videoContainer.setAttribute('data-fullscreen', 'true')
                this.fsButtonIcon.classList.remove('icon-fs')
                this.fsButtonIcon.classList.add('icon-fs-exit')
                this.fsButton.setAttribute(
                    'aria-label',
                    translate('closeFsVideo')
                )
            } else {
                videoContainer.setAttribute('data-fullscreen', 'false')
                this.fsButtonIcon.classList.remove('icon-fs-exit')
                this.fsButtonIcon.classList.add('icon-fs')
                this.fsButton.setAttribute(
                    'aria-label',
                    translate('showFsVideo')
                )
            }
        }

        // handle fs exit through OS native controls
        document.addEventListener('fullscreenchange', () => {
            setFullscreenData(!!document.fullscreenElement)
        })

        const isVideoPlaying = (video: HTMLVideoElement) =>
            !!(
                video.currentTime > 0 &&
                !video.paused &&
                !video.ended &&
                video.readyState > 2
            )

        const play = () => {
            if (isVideoPlaying(this.video)) {
                this.video.pause()
            } else {
                this.video.play()
            }
            this.playButtons.forEach((button) => {
                button
                    .querySelector('.icon')
                    .classList.toggle('icon-play-circle-solid')
                button
                    .querySelector('.icon')
                    .classList.toggle('icon-pause-circle-solid')
                if (isVideoPlaying(this.video)) {
                    // button change aria-label
                    button.setAttribute('aria-label', translate('pauseVideo'))
                } else {
                    button.setAttribute('aria-label', translate('playVideo'))
                }
            })
        }

        const mute = () => {
            if (this.video.muted) {
                this.video.muted = false
                this.muteButton.setAttribute(
                    'aria-label',
                    translate('muteVideo')
                )
            } else {
                this.video.muted = true
                this.muteButton.setAttribute(
                    'aria-label',
                    translate('unmuteVideo')
                )
            }
            this.muteButton
                .querySelector('.icon')
                .classList.toggle('icon-volume-up')
            this.muteButton
                .querySelector('.icon')
                .classList.toggle('icon-volume-off')
        }

        Array.from(this.playButtons).map((button) =>
            button.addEventListener('click', play)
        )
        if (this.muteButton) {
            this.muteButton.addEventListener('click', mute)
        }

        if (this.mainPlayButton) {
            this.mainPlayButton.addEventListener('click', () => {
                play()
            })
        }

        this.video.addEventListener('play', () => {
            if (!this.hasInteractedWithVideo) {
                videoContainer.classList.add('interacted')
            }
            this.video.classList.add('playing')
            if (this.playButtons) {
                this.playButtons.forEach((button) => {
                    button.setAttribute('aria-label', translate('pauseVideo'))
                    button.setAttribute('aria-hidden', 'false')
                })
            }
            if (this.mainPlayButton) {
                this.mainPlayButton.classList.add('hidden')
            }
            if (videoContainer.classList.contains('ce-video--controls')) {
                this.muteButton.setAttribute('aria-hidden', 'false')
                this.videoControls.classList.remove('hidden')
                this.videoControls.classList.add('flex')
            }
        })
    }

    private intersectionObserver(el) {
        // observer
        const autoplayHandler = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const video = entry.target.querySelector('video')
                    if (entry.isIntersecting) {
                        video.play()
                    } else {
                        video.pause()
                    }
                })
            },
            {threshold: [0.5]}
        )

        // observe element
        autoplayHandler.observe(el)
    }
}
