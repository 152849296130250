export default class EventsFilters {
    private form: HTMLFormElement

    constructor() {
        this.form = document.querySelector('.events__filters')

        if (this.form) this.init()
        
    }

    private init() {
        const options = this.form.querySelectorAll<HTMLInputElement>('.events__filter-option')

        options.forEach(option => {
            option.addEventListener('change', () => this.form.dispatchEvent(new Event('submit')))
        })
    }
}
